.__book-header {
  width: 33.33%;
  text-align: center;
  float: left;
}

.__book-header:active {
  color: #756d54;
}

.__book-header-count {
  color: #abb557;
  font-size: 4.5em;
}

.__book-header-name {
  color: #abb557;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.__booker-header-name:active {
  color: #756d54;
}
