.__alt-page-title {
  border-radius: 10px;
  color: #ffffff;
  display: block;
  font-size: 2em;
  margin: auto;
  margin-top: 30px;
  padding: 20px;
  text-align: center;
}
