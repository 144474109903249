.__button-primary {
  background-color: #abb557;
  border-radius: 10px;
  color: #ffffff;
  cursor: pointer;
  display: block;
  font-size: 1.1em;
  margin: auto;
  padding: 6px;
  height: 45px;
  width: 223px;
  border: 2px solid #abb557;
}

.__button-primary:disabled {
  cursor: not-allowed;
}
