.__alt-link {
  color: #ffffff;
  display: block;
  font-size: 1.1em;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
  text-decoration: none;
}

.__alt-link:hover {
  text-decoration: underline;
}
