.__page-title {
    border-radius: 10px;
    color: #756D54;
    display: block;
    font-size: 2em;
    margin: auto;
    margin-top: 30px;
    padding: 20px;
    text-align: center;
}
