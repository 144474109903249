.__error-message {
  background-color: #e65f5c;
  border-radius: 10px;
  color: #ffffff;
  display: block;
  font-size: 1.1em;
  overflow: auto;
  margin: auto;
  margin-bottom: 15px;
  margin-top: 15px;
  height: 60px;
  width: auto;
  border: 5px solid #f5c4c3;
  text-align: center;
}
