input, label {
    display: block;
}

.__input-label {
    color: #756D54;
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.1em;
}

.__text-input {
    border-radius: 12px;
    border: 1px solid #bbb;
    color: #aaa;
    font-size: 1.1em;
    margin: auto;
    margin-bottom: 38px;
    outline: 0;
    padding-left: 32px;
    padding: 6px;
    height: 40px;
    width: 291px;
}
